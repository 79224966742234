import axios from 'axios';

var init = function () {
  axios.get("/api/announcements/load/", {}).then(function (response) {
    const announcements_data = response.data ? response.data : [];
    if (announcements_data.length > 0) {
      let $announcements_wrapper = document.getElementById("announcements");
      announcements_data.forEach((announcement_data) => {
        let $announcement = document.createElement("div");
        $announcement.className = `announcement is-${announcement_data.style}`;
        $announcement.innerHTML = announcement_data.html;
        $announcements_wrapper.appendChild($announcement);
      });
    }
  });
};
window.addEventListener("load", init, false);
